<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="$t('label.employeeRole')+' : ' + nameModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
      class="modal-content-employee"
    >

      <CRow class="justify-content-center">
        <CCol sm="12" lg="9" class="text-center">
          <CCard>
            <CCardHeader class="text-left">
              <b>{{$t('label.employeeInfo')}}</b> 
            </CCardHeader>
            <CCardBody class="mb-3">
              <CRow class="mx-2">
                <CCol sm="12" lg="5">
                  <CRow class="text-left">
                    <CCol sm="12"  >
                        <CInput       
                          :label="$t('label.IdNumber')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                          addLabelClasses="required text-right"
                          v-uppercase
                          required
                          @keypress="ejecutarEnter"
                          :placeholder="$t('label.enterSearch')"
                          maxlength="20"
                          :is-valid="hasError($v.NumberId)"
                          v-model.trim="$v.NumberId.$model"
                          :invalid-feedback="errorMessage($v.NumberId)"
                          size="sm"
                        >
                        </CInput>
                    </CCol>
                    <CCol sm="12" class="mt-3" >
                      <CSelect
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                        :label="$t('label.role')"
                        addLabelClasses="required text-right"
                        :disabled="true"
                        :options="rolOptions"
                        :is-valid="hasError($v.StowageRoleId)"
                        v-model.trim="$v.StowageRoleId.$model"
                        :value.sync="StowageRoleId"
                        :invalid-feedback="errorMessage($v.StowageRoleId)"
                        size="sm"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="12" lg="5">
                  <CRow class="text-left">
                    <CCol sm="12"  >
                      <CInput
                          addLabelClasses="required text-right"
                          :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                          v-uppercase
                          maxlength="250"
                          @keypress="ejecutarEnter"
                          :label="$t('label.name')"
                          :placeholder="$t('label.name')"
                          :is-valid="hasError($v.Name)"
                          v-model.trim="$v.Name.$model"
                          :invalid-feedback="errorMessage($v.Name)"
                          size="sm"
                      />
                    </CCol>
                    <CCol sm="12" class="mt-3" >
                      <CInput  
                          addLabelClasses="required text-right"
                          :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                          v-uppercase
                          maxlength="20"
                          :label="$t('label.lastName')"
                          :placeholder="$t('label.lastName')"
                          :is-valid="hasError($v.LastName)"
                          v-model.trim="$v.LastName.$model"
                          :invalid-feedback="errorMessage($v.LastName)"
                          size="sm"
                      />
                    </CCol>
                  </CRow>          
                </CCol>
                <CCol sm="12" lg="2" class="pr-0"  >
                    <CButton
                      color="watch" size="sm" class="mr-1"
                       
                      :disabled="isSubmit"
                      v-c-tooltip="{content: $t('label.search'),placement: 'top'}"
                      @click="buscarId"
                    >
                      <CIcon name="cil-search" />
                    </CButton>
                    <CButton
                      color="add" size="sm" class="mr-1"
                      :disabled="isSubmit"
                       
                      @click="guardar"
                    
                      v-c-tooltip="{content: $t('label.add')}"
                    >
                      <CIcon name="checkAlt" />
                    </CButton>
                    <CButton
                      color="wipe" size="sm"
                      @click="limpiarDatos"
                      :disabled="isSubmit"
                       
                      v-c-tooltip="{content: $t('label.clearFields')}"
                    >
                      <CIcon name="cil-brush-alt" />
                    </CButton>
                </CCol>
                <!--<CCol sm="11"></CCol>
                <CCol sm="12" lg="1">
                  <CButton
                    color="success"
                    v-c-tooltip="{content: 'Excel'}"
                  >
                    <CIcon name="cil-file-excel" />
                  </CButton>
                </CCol>-->
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>  
        <CCol sm="12" lg="6">
          <!--CRow>
            <CCol sm="12" class="medio mb-3">
              <CCollapse :show="collapse">
                <CCard>
                  <CCardHeader>
                    <b class="w-75">{{$t('label.select')}}</b>
                    <CButton color="dark" 
                      class="float-right" 
                      size="sm" 
                      @click="collapse=false" >
                      X
                    </CButton>
                  </CCardHeader>
                  <CCardBody>
                    <dataTableExtended
                      class="align-center-row-datatable"
                      :items="items2"
                      :fields="fields2"
                      column-filter
                      :noItemsView="tableText.noItemsViewText"
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :items-per-page="5"
                      hover
                      small
                      sorter
                      pagination
                      
                    >
                      <template #Seleccione="{item}">
                        <td class="text-center">
                          <CButton color="add" size="sm" v-c-tooltip="{content: $t('label.select')}" @click="seleccionarDatos(item)" >
                            <CIcon name="checkAlt" />
                          </CButton>
                        </td>
                      </template>
                    </dataTableExtended>
                  </CCardBody>
                </CCard>
              </CCollapse>            
            </CCol> 
          </CRow-->
          <CRow>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                size="lg"
                :fields="fields"
                :items="realItemData"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loadingTable"
                hover
                sorter
              >
                <template #loading>
                  <loading/>
                </template>
                <template #Nro-filter>
                  <CSwitch
                    color="watch"
                    variant="3d"
                    size="sm"
                    :checked.sync="checkedTable"
                  ></CSwitch>
                </template>
                <template #Nro="{ item }">
                  <td class="text-center">
                    <CSwitch
                      color="watch"
                      variant="3d"
                      size="sm"
                      :checked.sync="item.selected"
                    ></CSwitch>
                  </td>
                </template>
                <template #Detalle="{ item }">
                  <td class="text-center">
                    <CButton color="edit" 
                      class="ml-1" 
                      size="sm" 
                      @click="editDatos(item)"
                      v-c-tooltip="{
                        content: $t('label.edit'),
                        placement: 'top'
                      }">
                      <CIcon name="pencil" />
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="1" class="d-flex align-items-center justify-content-center">
          <CButton
              color="info"
              @click="registerData()"
              v-c-tooltip="{
                  content: $t('label.AsignarCuadrilla'),
                  placement: 'top-end'
              }"
          >
              <CIcon name="cil-share"/>
          </CButton>
        </CCol>
        <CCol sm="12" lg="5">
          <dataTableExtended
              class="align-center-row-datatable"
              size="lg"
              :fields="fields"
              :items="itemsAux"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              :loading="loadingTable"
              hover
              sorter
          >
            <template #loading>
                <loading/>
              </template>
              <template #Detalle="{ item }">
                <td class="text-center">
                  <CButton color="wipe" 
                    class="ml-1" 
                    size="sm" 
                    v-c-tooltip="{content: $t('label.delete')}"
                    @click="ConfirmarEliminado(item)" >
                    <CIcon name="cil-trash" />
                  </CButton>
                </td>
              </template>
          </dataTableExtended>
        </CCol>
      </CRow>

      <template #footer>
        <!--<CButton outline color="success" @click="guardar" >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>-->
        <CButton color="wipe" @click="cerrarModal">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import RecursoDetalleValidations from '@/_validations/planificacion-estiba/recursoDetalleValidations';
import General from '@/_mixins/general';
import {alertPropertiesHelpers} from '@/_helpers/funciones';

function fields (){
  return [
    { key: 'Nro', label: '#', _style: 'min-width:45px;  width:5%;', filter: false, _classes: 'text-center',sorter: true },
    { key: 'NumberId', label:this.$t('label.IdNumber'), _style: 'width:30%;',filter: true,sorter: true },
    { key: 'Name', label: this.$t('label.name'), _style: 'width:30%;',filter: true,sorter: true },
    { key: 'LastName', label:this.$t('label.lastName'), _style: 'width:30%;',filter: true,sorter: true },
    { key: 'Detalle', label: '', _style: 'min-width:45px; width:5%; text-align:center', filter: false,sorter: false },
  ];
}

function fields2(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%;', filter: false, _classes: 'text-center',sorter: true },
    { key: 'NumberId', label:this.$t('label.IdNumber'), _style: 'width:30%;',filter: true,sorter: true },
    { key: 'Name', label: this.$t('label.name'), _style: 'width:30%;',filter: true,sorter: true },
    { key: 'LastName', label:this.$t('label.lastName'), _style: 'width:30%;',filter: true,sorter: true },
    { key: 'Seleccione', label: '', _style: 'min-width:45px; width:5%; text-align:center', filter: false,sorter: false },
  ];
}

function cerrarModal() {
  this.$emit('child-refresh', true);
  this.showModal=false;
}

function editDatos(item) {
  this.NumberId = item.NumberId;
  this.Name = item.Name.toUpperCase();
  this.LastName = item.LastName.toUpperCase();
  this.StowagePlanningRoleDetId = item.StowagePlanningRoleDetId;
  this.CompanyStaffId = item.CompanyStaffId ? item.CompanyStaffId : '';
  this.$nextTick(() => { this.$v.$touch() });
}

async function registerData(){
  let listado = [];
  await this.items.map(async(item,index) => {
    if(item.selected){
      let bandera = false;
      let cedula = item.NumberId;
      await this.itemsAux.map(async(item2) => {
        if(cedula == item2.NumberId && item2.Status=='ACTIVO'){
          bandera = true;
        }
      })
      if(bandera === false){
        listado.push({
          CompanyStaffId: item.CompanyStaffId ? item.CompanyStaffId : "",
          StowagePlanningRoleDetId: item.StowagePlanningRoleDetId ? item.StowagePlanningRoleDetId : "",
          StowagePlanningResourceId: this.StowagePlanningResourceId,
          NumberId: item.NumberId,
          Name: item.Name,
          LastName: item.LastName,
          Status: 1
        });
      }
    }
  })
  if(listado.length !== 0){
    this.loadingTable = true;
    this.$http.ejecutar('POST', 'StowagePlanningRoleDetail-insert', listado, { root: 'StowagePlanningRoleDetailJson' })
    .then(response => {
      let res = [...response.data.data];
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });

      let listado2 = Array;
      this.$http.get("StowagePlanningRoleDetail-by-StowagePlanningResourceId", { StowagePlanningResourceId: this.StowagePlanningResourceId })
      .then(response => {
        listado2 = response.data.data;
        let i = 1;
        this.itemsAux = listado2.map(listado => Object.assign({}, this.itemsAux, {
          Nro: i++,
          NumberId: listado.NumberId,
          Name: listado.Name,
          LastName: listado.LastName,
          CompanyStaffId: listado.CompanyStaffId,
          StowagePlanningRoleDetId: listado.StowagePlanningRoleDetId,
          Status : listado.Status,
          _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
        }));
        this.checkedTable = false;
        this.items.map(async (item) => {
          item.selected = false;
        })
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.loadingTable = false;
      });
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingTable = false;
    });
  }
  
}

async function listarDetalle () {
  this.items = [];
  this.loadingTable = true;
  let i = 1;

  let listado = Array;
  await this.$http.get("CompanyGangs-list-by-StowagePlanningResourceId", { StowagePlanningResourceId: this.StowagePlanningResourceId })
  .then(response => {
    listado = [...response.data.data].filter((ob) => ob.Status=='ACTIVO');
    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: i++,
      NumberId: listado.NumberId,
      Name: listado.Name,
      LastName: listado.LastName,
      StowagePlanningRoleDetId: listado.StowagePlanningRoleDetId,
      CompanyStaffId: listado.CompanyStaffId,
      selected: false,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });

  let listado2 = Array;
  await this.$http.get("StowagePlanningRoleDetail-by-StowagePlanningResourceId", { StowagePlanningResourceId: this.StowagePlanningResourceId })
  .then(response => {
    listado2 = response.data.data;
    i = 1;
    this.itemsAux = listado2.map(listado => Object.assign({}, this.itemsAux, {
      Nro: i++,
      NumberId: listado.NumberId,
      Name: listado.Name,
      LastName: listado.LastName,
      StowagePlanningRoleDetId: listado.StowagePlanningRoleDetId,
      CompanyStaffId: listado.CompanyStaffId,
      Status : listado.Status,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.loadingTable = false;
  });
}

function ejecutarEnter($event) {
    this.evaluador = true;
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
    
    if (keyCode == 13) {
      this.buscarId();
    } else {
      this.collapse = false;
    }
}

function buscarId() {
  if (!this.collapse) {
    let listado = Array;
    this.items2 = [];
    this.collapse = false;

    let searchKey = this.NumberId !== "" ? this.NumberId : this.Name;

    if (searchKey !== '') {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$http.get("StowagePlanningRoleDetail-list-search", { Search: searchKey })
      .then(response => {
        listado = [...response.data.data][0].RoleDetailJson;

        if (listado.length == 1) {
          this.NumberId = listado[0].NumberId.trim();
          this.Name = listado[0].Name;
          this.LastName = listado[0].LastName;
        } else if (listado.length > 1) {
          this.items2 = listado.map(listado => Object.assign({}, this.items2, {
            Nro: listado.Nro,
            NumberId: listado.NumberId.trim(),
            Name: listado.Name,
            LastName: listado.LastName,
          }));
          this.collapse = true;
        } else {
          this.$notify({
            group: 'container',
            title: '¡Mensaje!',
            text: this.$t('label.noResultsFound'),
            type: "error"
          });  
        }
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      });
    } else {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.enterEmployeeInfo'),
        type: "error"
      });
    }
  }
}

function guardar () {
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();
    
    if (this.$v.$invalid){
      this.loadingOverlay = false;
      this.isSubmit = false;
      return false;
    }

    let StowagePlanningRoleDetailJson    = [];
    let metodo      = '';
    let ruta        = '';

    if (this.StowagePlanningRoleDetId != '') {
      StowagePlanningRoleDetailJson = {
        StowagePlanningRoleDetId: this.StowagePlanningRoleDetId,
        CompanyStaffId: this.CompanyStaffId,
        StowagePlanningResourceId: this.StowagePlanningResourceId,
        NumberId: this.NumberId,
        Name: this.Name,
        LastName: this.LastName,
        Status: 1
      };
      metodo = 'PUT';
      ruta = "StowagePlanningRoleDetail-update";
    } else {
      StowagePlanningRoleDetailJson = {
        StowagePlanningResourceId: this.StowagePlanningResourceId,
        NumberId: this.NumberId,
        Name: this.Name,
        LastName: this.LastName
      };
      metodo = 'POST';
      ruta = "StowagePlanningRoleDetail-insert";
    }

    this.$http.ejecutar(metodo, ruta, StowagePlanningRoleDetailJson, { root: 'StowagePlanningRoleDetailJson' })
    .then(response => {
      let res = [...response.data.data];
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      this.loadingOverlay= false;
      this.isSubmit = false;
      this.listarDetalle();
      this.limpiarDatos();
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingOverlay= false;
      this.isSubmit = false;
    });

  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function ConfirmarEliminado(item) {
  this.$swal.fire(alertPropertiesHelpers(this,{
      text: `${this.$t('label.changeStatusQuestion')} ${item.NumberId}?`
    })
   ).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let res = [];

      let StowagePlanningRoleDetailJson = {
        StowagePlanningRoleDetId: item.StowagePlanningRoleDetId,
        StowagePlanningResourceId: this.StowagePlanningResourceId,
        NumberId: item.NumberId,
        Name: item.Name,
        CompanyStaffId: item.CompanyStaffId ? item.CompanyStaffId : '',
        LastName: item.LastName,
        Status: 0
      };

      this.$http.put("StowagePlanningRoleDetail-update", StowagePlanningRoleDetailJson, { root: 'StowagePlanningRoleDetailJson' })
      .then(response => {
        res = [...response.data.data];
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.listarDetalle();
        this.limpiarDatos();
        this.loadingOverlay= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingOverlay= false;
      });
    }
  });  
}

function limpiarDatos() {
  this.StowagePlanningRoleDetId = '';
  this.Name = '';
  this.LastName = '';
  this.NumberId = '';
  this.collapse = false;
  this.$nextTick(() => { this.$v.$reset() })
}

function seleccionarDatos(item) {
  this.NumberId = item.NumberId;
  this.Name = item.Name;
  this.LastName = item.LastName;
  this.collapse = false;
}

function data() {
  return {
    nameModal:'',
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    checkedTable:false,
    isSubmit: false,
    items: [],
    items2: [],
    itemsAux: [],
    rolOptions: [],
    //MODELO
    Name: '',
    LastName: '',
    StowageRoleId: '',
    NumberId: '',
    StowagePlanningResourceId: '',
    StowagePlanningRoleDetId: '',
    FgSupplier: false,
    CompanyStaffId: '',
    collapse: false,
  };
}

function optionsStatus(){
  return [
    { label: this.$t('label.ACTIVO'), value: 1 },
    { label: this.$t('label.INACTIVO'), value: 0 },
  ];
}

function realItemData(){
  let list = [];
  if(this.items.length !== 0){
    this.items.map(async(listado) => {
      let cedula = listado.NumberId;
      const Index = this.itemsAux.map(function(e) {
        return e.NumberId; 
      }).indexOf(cedula);
      if(Index == -1){
        list.push(listado);
      }
    })
  }
  return list;
}

export default {
  name: 'modal-empleado',
  data,
  props: {
    modal: null,
  },
  mixins: [General],
  directives: UpperCase,
  validations: RecursoDetalleValidations,
  computed: {
    fields,
    fields2,
    realItemData,
    optionsStatus,
  },
  watch: {
    modal: function () {
      if (this.modal) {
        this.nameModal = this.modal.ClientName;
        this.limpiarDatos();
        this.StowageRoleId = this.modal.StowageRoleId;
        /*if (this.modal.RoleJson.length > 1) {
          let arreglo = [];
          if (this.modal.PayRollId=="8E4A0880-7D8B-45BE-849A-DCB72CC46A66") {
            arreglo = this.modal.RoleJson.filter(x => x.ClientId!=null);
          } else {
            arreglo = this.modal.RoleJson.filter(x => x.ClientId==null);
          }
          this.StowagePlanningResourceId = arreglo[0].StowagePlanningResourceId;
          this.FgSupplier = arreglo[0].FgSupplier;
        } else {
          this.StowagePlanningResourceId = this.modal.StowagePlanningResourceId;
        }*/
        this.StowagePlanningResourceId = this.modal.StowagePlanningResourceId;        
        this.rolOptions.push({
          value: this.modal.StowageRoleId,
          label: this.modal.StowageRoleName
        })
        this.listarDetalle();
        this.showModal = true;
        this.$emit('cerrarModal');
      }
    },
    checkedTable: function(newValue){
      this.items.map(async(item) => {
        item.selected = newValue;
      });
    }
  },
  methods: {
    registerData,
    cerrarModal,
    listarDetalle,
    guardar,
    limpiarDatos,
    ejecutarEnter,
    buscarId,
    editDatos,
    ConfirmarEliminado,
    seleccionarDatos
  },
};
</script>
<style lang="scss" scope>
  /* .largo {
    width: 112% !important;
  }  
  .w-85 {
    width: 85% !important;
  }
.alto {
    max-height: 500px;
    overflow-y: auto;
  }
  .medio {
    max-height: 350px;
    overflow-y: auto;
  }*/

  .center-cell {
  text-align: center;
}
 
.modal-content-employee {
  .modal-content {
    width: 100% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
  .modal-xl {
    min-width: 95%;
  }
}
</style>
